import React from 'react'
import styled from 'styled-components'
import LeftArrow from '../../svgs/leftPointingArrow.svg'
import RightArrow from '../../svgs/rightPointingArrow.svg'

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 1.5rem 0;

  h2 {
    padding: 0 2rem;
    font-family: ${props => props.theme.fancyFont};
    font-size: 1.5rem;
    line-height: 1;
    margin: 0 !important;
  }

  svg {
    flex: 1;
  }
`

export const FancyTitle = ({ children }) => {
  return (
    <TitleWrapper>
      <LeftArrow />
      <h2>{children}</h2>
      <RightArrow />
    </TitleWrapper>
  )
}
